<template>
  <div class="row">
    <div class="col-12">

      <table class="table table-sm" style="color:white">
        <thead>
          <tr>
            <th>#</th>
            <th>Adet</th>
            <th>Paket</th>
            <th>Kargo Tipi</th>
        <!--    todo:   bu bilginin varlığı kargo detail tablosunda insert hatasına sebep oluyor.Müşteri kullanmadığı için gizledik Saliha bakacak     -->
            <th style="display: none">Ambalaj</th>
            <th>Desi</th>
            <th>Ağırlık</th>
            <th>
              <b-button class="btn-sm" size="sm" variant="success" @click="addDetail()">+</b-button>
            </th>
          </tr>
        </thead>
        <tbody>

          <CargoDetailRow v-for="(detail, index) in details" :key="`CargoDetailRow-${index}`" :detail="detail"
            :index="index" :options="options" :lookup="lookup" :paytypeid="paytypeid"
            @remove-detail="(val) => $emit('remove-detail', val)">
          </CargoDetailRow>

        </tbody>

      </table>
    </div>
  </div>
</template>

<script>
import CargoDetailRow from './CargoDetailRow.vue'
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    details: {
      type: Array,
      default: () => ([])
    },
    lookup: {
      type: Object,
      default: () => ({})
    },
    paytypeid: {
      type: [Number, String],
      default: () => null
    }
  },
  components: { CargoDetailRow },
  methods: {
    addDetail() {
      this.$emit('add-detail');
    }
  }
}
</script>